import { createStore } from 'vuex';

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    count: 0,
    sidebar: 'close',
};

// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    sidebarClose(state) {
        state.sidebar = 'close';
        state.count++;
    },
    sidebarOpen(state) {
        state.sidebar = 'open';
        state.count++;
    },
    increment(state) {
        state.count++;
    },
    decrement(state) {
        state.count--;
    },
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
    sidebarOpen: ({ commit }) => commit('sidebarOpen'),
    sidebarClose: ({ commit }) => commit('sidebarClose'),
    increment: ({ commit }) => commit('increment'),
    decrement: ({ commit }) => commit('decrement'),
    incrementIfOdd({ commit, state }) {
        if ((state.count + 1) % 2 === 0) {
            commit('increment');
        }
    },
    // incrementAsync({ commit }) {
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //             commit('increment');
    //             resolve();
    //         }, 1000);
    //     });
    // },
};

// getters are functions.
const getters = {
    sidebarState: (state) => (state.sidebar === 'open' ? 'open' : 'close'),
    evenOrOdd: (state) => (state.count % 2 === 0 ? 'even' : 'odd'),
};

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default createStore({
    state,
    getters,
    actions,
    mutations,
});
