<template>
    <div id="mapContainer"></div>
</template>

<script>
import { onBeforeMount, onMounted } from 'vue';
import 'leaflet/dist/leaflet.css';
import './static/leaflet.fullscreen.css';

import L from 'leaflet';

import geo from './static/geojson/spa.json';

export default {
    props: {
        healingSpa: {
            type: Object,
            default: geo,
        },
    },
    setup(props) {
        let map;

        // let simpleGeoJsonFeature = {
        //     type: 'Feature',
        //     properties: {
        //         nazwa: 'Coors Field',
        //         amenity: 'Baseball Stadium',
        //         popupContent: 'This is where the Rockies play!',
        //     },
        //     geometry: {
        //         type: 'Point',
        //         coordinates: [-104.99404, 39.75621],
        //     },
        // };

        onBeforeMount(() => {
            L.Icon.Default.mergeOptions({
                iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
                iconUrl: require('leaflet/dist/images/marker-icon.png'),
                shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
            });
        });

        onMounted(() => {
            let fullscreenScript = document.createElement('script');
            fullscreenScript.setAttribute('src', 'Leaflet.fullscreen.min.js');
            document.head.appendChild(fullscreenScript);

            console.log(props.healingSpa);
            // coordinates limiting the map
            // function getBounds() {
            //     const southWest = new L.LatLng(38.0, 5.0);
            //     const northEast = new L.LatLng(55.0, 24.0);
            //     return new L.LatLngBounds(southWest, northEast);
            // }

            map = L.map('mapContainer', {
                minZoom: 5,
                maxZoom: 18,
                fullscreenControl: true,
                // Bounds: [
                //     //south west
                //     [18.0, -16.0],
                //     //north east
                //     [67.0, 53.0],
                // ],
            }).fitBounds([
                [42.0, 7.0],
                [53.0, 24.0],
            ]);

            // map.on('moveend', function () {
            //     alert(map.getBounds());
            // });

            function onEachFeature(feature, layer) {
                // does this feature have a property named popupContent?
                if (feature.properties && feature.properties.nazwa) {
                    layer.bindPopup(feature.properties.nazwa + feature.properties.kraj + feature.properties.region);
                }
            }

            L.geoJSON(props.healingSpa, {
                onEachFeature: onEachFeature,
            }).addTo(map);

            // adding geojson by fetch
            // of course you can use jquery, axios etc.
            //fetch('map.geojson')
            //    .then(function (response) {
            //        return response.json();
            //    })
            //    .then(function (data) {
            //        // use geoJSON
            //        L.geoJSON(data, { onEachFeature: onEachFeature }).addTo(this.map);
            //    });
            //

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(map);
        });
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mapContainer {
    width: 100vw;
    height: 100vh;
}
</style>
