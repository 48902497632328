<template>
    <div @keypress.space="keyPressSpace">
        <!--
    <HelloWorld msg="Wiadomość z głównej"/>
    -->
        <div id="logo">
            <img alt="healingplaces logo" src="./assets/HealingPlaces-RGB.jpg" />
        </div>
        <!-- <VueMap /> -->
        <SideBar />
        <MainMap />
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
//!Components imports
import MainMap from './components/MainMap.vue';
import SideBar from './components/SideBar.vue';

export default {
    components: {
        MainMap,
        SideBar,
    },
    setup() {
        const store = useStore();

        // wysłanie zmienniej do dowolnego childa, nie działa
        //provide('key', 'halo');

        function keyPressSpace() {
            let sidebarState = computed(() => store.getters.sidebarState).value;
            if (sidebarState === 'open') {
                store.dispatch('sidebarClose');
                console.log('close');
            } else {
                store.dispatch('sidebarOpen');
                console.log('open');
            }
        }

        return { keyPressSpace };
    },
};
</script>

<style>
/* this is a comment
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}


W healing
<link href="https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,600,700" rel="stylesheet" type="text/css">

było
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,600,700&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap'); */

*,
:after,
:before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    height: 100%;
}

/* usunięto
#mapContainer po html
*/
body,
html {
    width: 100%;
    height: 100%;
}

body {
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: #f1f1f1;
}

/* Logo Interreg */
#logo {
    display: flex;
    position: absolute;
    top: 25px;
    right: 25px;
    max-width: 690px;
    z-index: 401;
    background: #fff;
    box-shadow: none;
    overflow: hidden;
    height: 12%;
}

svg:not(:root) {
    overflow: hidden;
}

.hidden {
    display: none;
}

.leaflet-left {
    transform: translateX(55px);
    transition: transform 850ms;
    z-index: 402;
}

.active-sidebar .leaflet-left {
    transform: translateX(490px);
    transition: transform 350ms;
    z-index: 402;
}

@media (min-width: 768px) {
    .leaflet-left {
        transform: translateX(55px);
    }
}
</style>
