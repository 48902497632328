<template>
    <el-col>
        <el-row>
            <h3>HealingPlaces Tool for impact assessment of SPAs development</h3>
        </el-row>
        <el-row>
            <el-select @change="changeCountry" v-model="country" class="m-2" placeholder="Select country">
                <el-option v-for="item in countries" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-model="spa" class="m-2" placeholder="Select spa">
                <el-option v-for="item in state.spas" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
        </el-row>

        <h4>Basic data</h4>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="date" label="Date" width="100" />
            <el-table-column prop="name" label="Name" width="80" />
            <el-table-column prop="address" label="Address" />
        </el-table>

        <h4>Basic indicators</h4>
    </el-col>
</template>

<script>
import { reactive, ref } from 'vue';
export default {
    setup() {
        const countries = [
            {
                value: 'Austria',
                label: 'Austria',
            },
            {
                value: 'Croatia',
                label: 'Croatia',
            },
            {
                value: 'Czechia',
                label: 'Czechia',
            },
            {
                value: 'Hungary',
                label: 'Hungary',
            },
            {
                value: 'Italy',
                label: 'Italy',
            },
            {
                value: 'Poland',
                label: 'Poland',
            },
            {
                value: 'Slovenia',
                label: 'Slovenia',
            },
        ];

        const country = ref('');

        const state = reactive({
            spas: [
                {
                    value: 'none',
                    label: 'Choose first country',
                },
            ],
        });

        const spa = ref('');

        function changeCountry(e) {
            if (e === 'Slovenia') {
                console.log('wybrano ' + e);
                state.spas = [
                    {
                        value: 'slo',
                        label: 'slo spa',
                    },
                ];
            } else {
                state.spas = [
                    {
                        value: 'non',
                        label: 'non spa',
                    },
                ];
            }
        }

        // spas = computed((kraj) => {
        //     if (kraj === 'Slovenia') {
        //         console.log('wybrano' + kraj);
        //         return [
        //             {
        //                 value: 'slo',
        //                 label: 'slo spa',
        //             },
        //         ];
        //     }
        //     return [
        //         {
        //             value: 'non',
        //             label: 'non spa',
        //         },
        //     ];
        // });

        // watch(country, (kraj) => {
        //     if (kraj === 'Slovenia') {
        //         console.log('wybrano' + kraj);
        //         spas = computed([
        //             {
        //                 value: 'vr1',
        //                 label: 'vr1',
        //             },
        //             {
        //                 value: 'vr2',
        //                 label: 'vr2',
        //             },
        //         ]);
        //     }
        // });

        const tableData = [
            {
                date: '2016-05-03',
                name: 'Tom',
                address: 'No. 189, Grove St, Los Angeles',
            },
            {
                date: '2016-05-02',
                name: 'Tom',
                address: 'No. 189, Grove St, Los Angeles',
            },
            {
                date: '2016-05-04',
                name: 'Tom',
                address: 'No. 189, Grove St, Los Angeles',
            },
            {
                date: '2016-05-01',
                name: 'Tom',
                address: 'No. 189, Grove St, Los Angeles',
            },
        ];

        return { country, countries, state, changeCountry, tableData, spa };
    },
};
</script>

<style scoped>
td,
th {
    border: 2px solid black;
}
</style>
