<template>
    <SvgIcons />
    <div class="sidebar">
        <button aria-label="close sidebar" type="button" class="close-button" @click="clickSidebarCloseButton">
            <svg><use xlink:href="#icon-clear" /></svg>
        </button>

        <ul class="sidebar-menu">
            <li
                v-for="item in props.menu"
                :key="item.name"
                :data-item="`${item.name}`"
                class="menu-item"
                :id="`id-${item.name}`"
            >
                <svg><use :xlink:href="`#${item.icon}`"></use></svg>
            </li>
        </ul>

        <div class="sidebar-content">
            <ContentItem id="main" title="Healing Places - Main">
                <HealingPlaces />
            </ContentItem>
            <ContentItem id="person" title="contentItem"></ContentItem>
            <ContentItem id="spalist" title="List of Spa manualy"></ContentItem>
            <ContentItem id="settings" title="Settings">
                <SettingsCard />
            </ContentItem>
        </div>
    </div>
</template>

<script>
import SvgIcons from './static/SvgIcons.vue';
import ContentItem from './contents/ContentItem.vue';
import HealingPlaces from './contents/HealingPlaces.vue';
import SettingsCard from './contents/SettingsCard';
import { useStore } from 'vuex';
import { onMounted, watch, computed } from 'vue';

import menu_json from './static/menu.json';

export default {
    components: {
        SvgIcons,
        ContentItem,
        HealingPlaces,
        SettingsCard,
    },
    props: {
        menu: {
            type: Array,
            default: menu_json,
        },
    },

    setup(props) {
        const store = useStore();

        function clickSidebarCloseButton() {
            store.dispatch('sidebarClose');
        }

        watch(
            () => store.getters.sidebarState,
            (newVal, oldVal) => {
                if (newVal === 'close') {
                    console.log('fire closeSidebar->new: ' + newVal + ' old: ' + oldVal);
                    closeSidebar();
                } else {
                    console.log('fire event ->new: ' + newVal + ' old: ' + oldVal);

                    //---------------------------to sie powtarza prawie w ---------------------------------------
                    const target = document.querySelector('#id-main');

                    // jeżeli przycisk jest aktywny lub nie ma aktywnego sidebara ??
                    if (target.classList.contains('active-item') || !document.querySelector('.active-sidebar')) {
                        document.body.classList.toggle('active-sidebar'); //dodaje do klasy body active-sidebar
                    }
                    showContent('main'); // pokazuje kontent wybranego

                    // add active class to menu item
                    addRemoveActiveItem(target, 'active-item');
                }
            }
        );

        onMounted(() => {
            console.log(props.menu);

            //TODO tutaj można iterować po tablicy menu i dodać lisnery
            //TODO albo dodac eventy do buttonow w template !!! chyba lepsze rozwiazanie
            //TODO nie mogą byc dwa lisenery wszystko misi załatwiacc watcher !!!!!

            const menuItems = document.querySelectorAll('.menu-item');
            // add to all menu button event
            menuItems.forEach((item) => {
                item.addEventListener('click', (e) => {
                    const target = e.target;

                    console.log('Click menu: ' + item.dataset.item);

                    // jeżeli przycisk jest aktywny lub nie ma aktywnego sidebara ??
                    if (target.classList.contains('active-item') || !document.querySelector('.active-sidebar')) {
                        document.body.classList.toggle('active-sidebar'); //dodaje do klasy body active-sidebar
                    }
                    showContent(target.dataset.item); // pokazuje kontent wybranego

                    // add active class to menu item
                    addRemoveActiveItem(target, 'active-item');
                });
            });

            // --------------------------------------------------
            // close when click esc
            // document.addEventListener('keydown', function (event) {
            //     if (event.key === 'Escape') {
            //         closeSidebar();
            //     }
            // });

            // close sidebar when click outside
            document.addEventListener('click', (e) => {
                if (!e.target.closest('.sidebar')) {
                    // closeSidebar();
                }
            });
        });

        // remove active class from menu item and content
        function addRemoveActiveItem(target, className) {
            const element = document.querySelector(`.${className}`); //szuka po klasie
            target.classList.add(className);
            if (!element) return;
            element.classList.remove(className);
        }

        // show specific content
        function showContent(dataContent) {
            console.log('showContent: ' + dataContent);
            const idItem = document.querySelector(`#${dataContent}`); //szuka po id bo jest #
            addRemoveActiveItem(idItem, 'active-content');
        }

        // --------------------------------------------------
        // close sidebar
        function closeSidebar(e) {
            console.log(e);
            document.body.classList.remove('active-sidebar');
            const element = document.querySelector('.active-item');
            const activeContent = document.querySelector('.active-content');
            if (!element) return;
            element.classList.remove('active-item');
            activeContent.classList.remove('active-content');
        }

        return {
            clickSidebarCloseButton,
            sidebarState: computed(() => store.getters.sidebarState),
            props,
        };
    },
};
</script>

<!-- Add "scoped"  attribute to limit CSS to this component only -->
<style scoped>
.sidebar::before {
    position: absolute;
    content: '';
    z-index: -1;
    top: 0;
    left: 44px;
    width: 100%;
    height: 40px;
    background: #0052b1;
}

.sidebar {
    display: flex;
    gap: 1rem;
    position: absolute;
    width: 46px;
    max-width: 480px;
    z-index: 403;
    background: #fff;
    box-shadow: none;
    overflow: hidden;
    border-right: 2px solid rgba(0, 0, 0, 0.2);
    height: 100%;
}

.sidebar svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: #3f3f3f;
    pointer-events: none;
}

.sidebar .close-button svg {
    fill: #fff;
}

.active-sidebar .sidebar::before {
    left: 42px;
}

.active-sidebar .sidebar {
    max-width: 760px;
    width: 100%;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 100%;
}

.sidebar-menu li {
    padding: 10px 11px;
    cursor: pointer;
}

.sidebar-menu li:last-child {
    margin-top: auto;
}

.sidebar-menu li:hover:last-child svg {
    animation: rotation 500ms ease-in;
    transform-origin: center;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@media (min-width: 768px) {
    .sidebar {
        top: 10px;
        left: 10px;
        height: calc(100% - 20px);
        border: 2px solid rgba(0, 0, 0, 0.2);
        transition: width 500ms;
    }

    .active-sidebar .sidebar {
        max-width: 480px;
        width: 100%;
        transition: width 1s;
    }
}

.active-item {
    background: #0052b1;
}

.active-sidebar .close-button {
    pointer-events: all;
    opacity: 1;
    transition: opacity 100ms ease-in-out;
}

.close-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 8px;
    border: none;
    padding: 5px;
    margin: 0;
    padding: 0;
    border-radius: 3px;
    background: none;
    opacity: 0;
    pointer-events: none;
}

.active-item svg {
    fill: #fff;
}

.sidebar-content {
    width: 100%;
    opacity: 0;
    pointer-events: none;
    margin-bottom: 15px;
    transition: opacity 500ms;
    overflow: hidden;
}

.active-sidebar .sidebar-content {
    opacity: 1;
    pointer-events: visible;
    transition: opacity 200ms;
}

.sidebar-content h2 {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    white-space: nowrap;
    width: calc(100% - 10px);
    height: 25px;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
