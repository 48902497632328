<template>
    <div class="item-content" :id="`${id}`">
        <h2>{{ title }}</h2>
        <div class="content">
            <el-scrollbar>
                <el-space direction="vertical" fill style="padding-right: 15px">
                    <slot>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore, optio quisquam architecto
                        sequi itaque atque aliquid perspiciatis dolor, quo cumque iste sed rerum temporibus! Quam
                        architecto vel similique eaque deserunt. Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Quae facere ducimus rem temporibus a libero odit nisi. Animi ab harum nesciunt modi,
                        officiis accusamus nulla nisi rerum recusandae laborum. Eos? Lorem ipsum dolor sit, amet
                        consectetur adipisicing elit. Reprehenderit aliquid sapiente dignissimos, quae facilis illo
                        voluptates doloribus minus architecto nesciunt non optio voluptatem natus temporibus magnam
                        fugit omnis pariatur tenetur? Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Aspernatur culpa ullam modi praesentium, possimus alias aliquam sed quia dicta quaerat corporis
                        consectetur recusandae labore cumque qui, veniam, commodi harum libero. Lorem ipsum dolor sit
                        amet, consectetur adipisicing elit. Nulla nostrum ipsa iste hic reprehenderit dolore magnam
                        expedita optio distinctio odit, cupiditate consequuntur nam necessitatibus? Asperiores totam
                        delectus labore dignissimos est!
                    </slot>
                </el-space>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
        title: String,
    },
};
</script>

<style>
.item-content {
    display: none;
    margin-top: 8px;
    /* padding-right: 10px; */
    height: 100%;
}

.item-content .content {
    overflow: auto;
    margin-top: 15px;
    height: calc(100% - 50px);
    /* padding-right: 10px; */
}

.active-content {
    display: block;
}

.active-content h2 {
    color: #fff;
}
</style>
