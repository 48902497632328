<template>
    <el-space wrap>
        Settings

        <el-divider />
        Clicked: {{ count }} times, count is {{ evenOrOdd }}.
        <el-divider />

        <el-button @click="increment">+</el-button>
        <el-divider />
        <el-button @click="decrement">-</el-button>
        <el-divider />
        <el-button @click="incrementIfOdd">Increment if odd</el-button>
        <el-divider />
        <el-button @click="incrementAsync">Increment async</el-button>
        <el-divider />
        <el-card class="box-card">
            <template #header>
                <div class="card-header">
                    <span>Card name</span>
                    <el-button class="button" text>Operation button</el-button>
                </div>
            </template>
            <div v-for="o in 4" :key="o" class="text item">{{ 'List item ' + o }}</div>
        </el-card>
    </el-space>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        return {
            count: computed(() => store.state.count),
            evenOrOdd: computed(() => store.getters.evenOrOdd),
            increment: () => store.dispatch('increment'),
            decrement: () => store.dispatch('decrement'),
            incrementIfOdd: () => store.dispatch('incrementIfOdd'),
            incrementAsync: () => store.dispatch('incrementAsync'),
        };
    },
};
</script>
